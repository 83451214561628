import React from 'react';
import { SafeComponent } from '@fiverr-private/orca';
import { Container } from '@fiverr-private/layout_components';
import VideoHeroBanner from '../VideoHeroBanner';
import Hero from '../Hero';

const VideoHeroBannerWrapper = () => (
  <>
    <Container display={{ default: 'none', md: 'block' }}>
      <VideoHeroBanner />
    </Container>
    <Container display={{ default: 'block', md: 'none' }}>
      <Hero />
    </Container>
  </>
);

export default SafeComponent(VideoHeroBannerWrapper);
