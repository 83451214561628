import React from 'react';
import { useMediaQuery } from 'react-responsive';
import SearchBar from '@fiverr-private/search_bar';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { theme } from '@fiverr-private/theme';
import SafeComponent from '../../../../logged_out_homepage/shared/SafeComponent';
import { titleCustomClass } from './styles.ve.css';

const Header = () => {
  const isScreenSizeLessThanSm = useMediaQuery({ query: theme.breakpointsReverse.sm });

  return (
    <Stack gap="10" alignItems="center" direction="column">
      <Typography as="h1" color="white" textAlign="center" className={titleCustomClass} style={{ fontWeight: 280 }}>
        <I18n k="logged_out_homepage.hero.title_freelancers" />
      </Typography>
      <Container width={{ default: '100%', md: '436px', lg: '560px', xl: '616px' }}>
        <SearchBar
          placeholder={translate(
            isScreenSizeLessThanSm ? 'logged_out_homepage.hero.search' : 'logged_out_homepage.hero.try_mobile_app'
          )}
          source="main_banner"
          submitButtonType="button_inside"
        />
      </Container>
    </Stack>
  );
};

export { Header };
export default SafeComponent(Header);
