import React, { memo } from 'react';
import { Video as VideoComponent } from '@fiverr-private/media';
import { VIDEO_URL } from './constants';

export const GoVideo = () => (
  <VideoComponent
    autoPlay
    playsInline
    controls={false}
    loop={true}
    preload="auto"
    muted
    borderRadius="2xl"
    width="100%"
    height="100%"
    maxWidth={{ default: '100%', sm: '472px', md: '400px', lg: '576px', xl: '600px' }}
    maxHeight={{ default: '196px', sm: '314px', md: '267px', lg: '384px', xl: '400px' }}
    order={{ default: 1, md: 2 }}
    sources={[
      {
        src: VIDEO_URL,
        type: 'video/mp4',
      },
    ]}
  />
);

export const MemoizedGoVideo = memo(GoVideo);
