import React, { memo } from 'react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { BQ_IMP_TOP_BANNER_IS_SHOWN } from '../../../logged_out_homepage/utils/biEvents/events/bigQueryEvents';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { getImpressionEnrichment } from '../../../logged_out_homepage/utils/biEvents';
import { Header } from './Header';

import styles from './index.module.scss';

const Hero = () => {
  const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_TOP_BANNER_IS_SHOWN }));

  return (
    <div className={styles.wrapper} ref={ref}>
      <Header />
    </div>
  );
};

export default SafeComponent(Hero);
export const MemoizedHero = memo(SafeComponent(Hero));
