const BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE = 'clicked_guest_homepage_category_image';
const BQ_CLICKED_HOMEPAGE_LO_SEARCH_TAG = 'clicked_homepage_lo_search_tag';
const BQ_CLICKED_HOMEPAGE_LO_GET_STARTED = 'clicked_homepage_lo_get_started';
const BQ_CLICKED_HOMEPAGE_LO_BUSINESS_SERVICES = 'clicked_homepage_lo_business_services';
const BQ_CLICKED_HOMEPAGE_LO_CARD_MADE_ON_FIVERR = 'clicked_homepage_lo_card_made_on_fiverr';
const BQ_CLICKED_BRAND_VIDEO_PREVIEW = 'clicked_on_brand_campaign_thumbnail_video';
const BQ_SHOW_BRAND_VIDEO_PLAYER = 'brand_campaign_video_player_show';
const BQ_SHOW_BRAND_VIDEO_THUMBNAIL = 'brand_campaign_video_thumbnail_show';
const BQ_CLOSED_BRAND_VIDEO_PLAYER = 'closed_brand_campaign_video_player';
const BQ_CLICKED_BRAND_LANDING_PAGE_LINK = 'brand_campaign_landing_page_click';

const BQ_CLICKED_POPULAR_SERVICES_CAROUSEL = 'clicked_on_popular_services_carousel';
const BQ_CLICKED_GETTING_STARTED = 'clicked_on_getting_started';
const BQ_CLICKED_TESTIMONAILS_VIDEO = 'clicked_on_testimonials_video';
const BQ_CLICKED_LM_BANNER = 'clicked_on_LM_banner';
const BQ_CLICKED_CTA_BANNER = 'clicked_on_CTA_banner';
const BQ_CLICKED_VONTELLE_CARD = 'clicked_on_vontelle_sc_carousel';
const BQ_CLICKED_USPSS_BUTTON = 'clicked_on_uspss_button';
const BQ_CLICKED_GO_START_GENERATING = 'clicked_on_start_generating';
const BQ_HOMEPAGE_CLICK = 'Home_page_click';
const BQ_HOMEPAGE_IMP = 'Home_page_impression';

const BQ_HOVERED_USP = 'hovered_on_USPs';
const BQ_HOVERED_INSPIRE = 'hovered_on_Inspire';
const BQ_HOVERED_TESTIMONIALS_VIDEO = 'hovered_on_testimonials_video';

const BQ_IMP_TOP_BANNER_IS_SHOWN = 'top_banner_is_shown';
const BQ_IMP_POPULAR_SERVICES_CAROUSEL_IS_SHOWN = 'popular_services_carousel_is_shown';
const BQ_IMP_CATEGORY_ICONS_IS_SHOWN = 'category_icons_is_shown';
const BQ_IMP_GETTING_STARTED_IS_SHOWN = 'getting_started_is_shown';
const BQ_IMP_USPSS_IS_SHOWN = 'uspss_is_shown';
const BQ_IMP_LOHP_VIDEO_IS_SHOWN = 'lohp_video_is_shown';
const BQ_IMP_FIP_IS_SHOWN = 'fip_banner_is_shown';
const BQ_IMP_TESTIMONIALS_IS_SHOWN = 'testimonals_is_shown';
const BQ_IMP_LM_BANNER_IS_SHOWN = 'lm_banner_is_shown';
const BQ_IMP_INSIPRE_IS_SHOWN = 'inspire_is_shown';
const BQ_IMP_GUIDES_IS_SHOWN = 'guides_is_shown';
const BQ_IMP_CTA_BANNER_IS_SHOWN = 'cta_banner_is_shown';
const BQ_IMP_VONTELLE_IS_SHOWN = 'viewed_vontelle_sc_carousel';
const BQ_IMP_GO_MEET_FIVERR_GO_IS_SHOWN = 'viewed_meet_fiverr_go_banner';

const BQ_VIDEO_PLAY = 'video_play';
const BQ_VIDEO_PAUSE = 'video_pause';
const BQ_POPULAR_SERVICE_CLICK_WEBSITE_DEVELOPMENT = 'website-development-header-click';
const BQ_POPULAR_SERVICE_CLICK_ARCHITECTURE_AND_INTERIOR = 'Architecture-and-interior-header-click';
const BQ_POPULAR_SERVICE_CLICK_VIDEO_EDITING = 'video-editing-header-click';
const BQ_POPULAR_SERVICE_CLICK_LOGO_DESIGN = 'logo-design-header-click';

export {
    BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE,
    BQ_CLICKED_HOMEPAGE_LO_SEARCH_TAG,
    BQ_CLICKED_HOMEPAGE_LO_GET_STARTED,
    BQ_CLICKED_HOMEPAGE_LO_BUSINESS_SERVICES,
    BQ_CLICKED_HOMEPAGE_LO_CARD_MADE_ON_FIVERR,
    BQ_CLICKED_BRAND_VIDEO_PREVIEW,
    BQ_SHOW_BRAND_VIDEO_THUMBNAIL,
    BQ_SHOW_BRAND_VIDEO_PLAYER,
    BQ_CLOSED_BRAND_VIDEO_PLAYER,
    BQ_CLICKED_BRAND_LANDING_PAGE_LINK,
    BQ_CLICKED_POPULAR_SERVICES_CAROUSEL,
    BQ_CLICKED_GETTING_STARTED,
    BQ_CLICKED_TESTIMONAILS_VIDEO,
    BQ_CLICKED_LM_BANNER,
    BQ_CLICKED_CTA_BANNER,
    BQ_HOVERED_USP,
    BQ_HOVERED_INSPIRE,
    BQ_HOVERED_TESTIMONIALS_VIDEO,
    BQ_IMP_TOP_BANNER_IS_SHOWN,
    BQ_IMP_POPULAR_SERVICES_CAROUSEL_IS_SHOWN,
    BQ_IMP_CATEGORY_ICONS_IS_SHOWN,
    BQ_IMP_GETTING_STARTED_IS_SHOWN,
    BQ_IMP_USPSS_IS_SHOWN,
    BQ_IMP_LOHP_VIDEO_IS_SHOWN,
    BQ_IMP_FIP_IS_SHOWN,
    BQ_IMP_TESTIMONIALS_IS_SHOWN,
    BQ_IMP_LM_BANNER_IS_SHOWN,
    BQ_IMP_INSIPRE_IS_SHOWN,
    BQ_IMP_GUIDES_IS_SHOWN,
    BQ_IMP_CTA_BANNER_IS_SHOWN,
    BQ_IMP_VONTELLE_IS_SHOWN,
    BQ_CLICKED_VONTELLE_CARD,
    BQ_CLICKED_USPSS_BUTTON,
    BQ_CLICKED_GO_START_GENERATING,
    BQ_IMP_GO_MEET_FIVERR_GO_IS_SHOWN,
    BQ_VIDEO_PLAY,
    BQ_VIDEO_PAUSE,
    BQ_POPULAR_SERVICE_CLICK_WEBSITE_DEVELOPMENT,
    BQ_POPULAR_SERVICE_CLICK_ARCHITECTURE_AND_INTERIOR,
    BQ_POPULAR_SERVICE_CLICK_VIDEO_EDITING,
    BQ_POPULAR_SERVICE_CLICK_LOGO_DESIGN,
    BQ_HOMEPAGE_CLICK,
    BQ_HOMEPAGE_IMP,
};
