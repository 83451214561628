import React, { memo } from 'react';
import { Section, Stack } from '@fiverr-private/layout_components';
import { Box } from '@fiverr-private/theme';
import { useImpressionItem } from '@fiverr-private/impressionable';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { getImpressionEnrichment } from '../../../logged_out_homepage/utils/biEvents';
import { BQ_HOMEPAGE_IMP } from '../../../logged_out_homepage/utils/biEvents/events/bigQueryEvents';
import Wrapper from './Wrapper';
import styles from './styles.module.scss';
import GoVideo from './GoVideo';

const GoBanner = () => {
  const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_HOMEPAGE_IMP }));

  return (
    <Section paddingTop="0" paddingBottom="0">
      <Box className={styles.container} borderRadius="2xl">
        <Stack
          ref={ref}
          minHeight={{ default: 'auto', md: '704px', lg: '436px', xl: '528px' }}
          height="auto"
          alignItems="center"
          position="relative"
          paddingTop={{ default: '6', sm: '10', lg: '12', xl: '16' }}
          paddingBottom={{ default: '8', sm: '10', lg: '12', xl: '16' }}
          paddingRight={{ default: '5', sm: '8', lg: '10', xl: '16' }}
          paddingLeft={{ default: '5', sm: '8', lg: '10', xl: '16' }}
          borderRadius="2xl"
          width="100%"
          overflow="hidden"
          direction={{ default: 'column', md: 'row' }}
          gap={{ default: '5', sm: '6', md: '10', lg: '16', xl: '20' }}
          marginBottom={{ default: '10', sm: '12', md: '12', lg: '10' }}
        >
          <Wrapper />
          <GoVideo />
        </Stack>
      </Box>
    </Section>
  );
};

export { GoBanner };
export default SafeComponent(GoBanner);
export const MemoizedGoBanner = memo(SafeComponent(GoBanner));
